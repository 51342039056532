import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Layout } from 'antd';

import TopMenu from './component/TopMenu';

import { types, config } from './config/config';
import { gaEvent, fetchContent } from './util/util';

import './App.css';

const { Content } = Layout;
const { application, google } = config;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fontSize: 14,
      currentIndex: -1,
      api: application.url.prod,
      gaId: google.GAId.prod,
      contents: [],
      currentContent: '',
      currentAudio: null,
      audioList: []
    };

    this.saveFontSize = this.saveFontSize.bind(this);
    this.updateModule = this.updateModule.bind(this);
    this.composeContent = this.composeContent.bind(this);
    this.registerAudio = this.registerAudio.bind(this);
  }

  componentDidMount() {
    let { api, gaId } = this.state;

    if (-~(window.location.hostname.indexOf('localhost'))) {
      api = application.url.local;
      gaId = google.GAId.local;
    }

    let oldFontSize = window.localStorage.getItem('fontSize') || 14;

    oldFontSize = parseInt(oldFontSize);
    if (oldFontSize > 70) {
      oldFontSize = 70;
    }
    if (oldFontSize < 14) {
      oldFontSize = 14;
    }

    this.updateModule(0);

    this.setState({
      fontSize: oldFontSize,
      api,
      gaId
    });

    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  registerAudio(index) {
    let { currentAudio, playEvent, pauseEvent, endEvent, currentIndex } = this.state;
    if (index !== currentIndex) {
      // remove the current event first
      if (currentAudio) {
        currentAudio.removeEventListener('play', playEvent);
        currentAudio.removeEventListener('pause', pauseEvent);
        currentAudio.removeEventListener('ended', endEvent);
      }

      playEvent = event => {
        // console.log('play', event.target.id);
        gaEvent({action: 'play', label: event.target.id});
      };

      pauseEvent = event => {
        // console.log('pause', event.target.id);
        gaEvent({action: 'pause', label: event.target.id});
      };

      endEvent = event => {
        // console.log('ended', event.target.id);
        gaEvent({action: 'ended', label: event.target.id});
      };

      setTimeout(_ => {
        let elem = document.querySelectorAll("audio");

        elem.forEach(el => {
          if (el.id === types[index].name) {
            this.setState({
              currentAudio: el,
              playEvent,
              pauseEvent,
              endEvent
            });

            el.addEventListener('play', playEvent, false);
            el.addEventListener('pause', pauseEvent, false);
            el.addEventListener('ended', endEvent, false);
          }
        })
      }, 500);
    }
  }

  async composeContent(index) {
    let { contents, currentAudio } = this.state;
    let currentContent;
    let allContent = [...contents];

    if (contents[index] && currentAudio) {
      currentContent = contents[index];
    } else {
      currentContent = await fetchContent(types[index]);
      if (!currentContent) {
        currentContent = '';
      }

      allContent[index] = currentContent;
    }

    this.setState({
      currentIndex: parseInt(index),
      currentContent,
      contents: allContent
    });
  }

  saveFontSize(add) {
    let { fontSize } = this.state;
    let newFontSize = fontSize;

    if (add) {
      newFontSize += 3;
    } else {
      newFontSize -= 3;
    }

    if (newFontSize > 70) {
      newFontSize = 70;
    }
    if (newFontSize > 0) {
      window.localStorage.setItem('fontSize', newFontSize);
    }
    this.setState({
      fontSize: newFontSize
    });
  };

  async updateModule(index) {
    var elem = document.querySelectorAll("audio");

    if (elem.length > 0) {
      elem.forEach(el => {
        el.pause();
        el.currentTime = 0;
      })
    }

    this.composeContent(index);
    this.registerAudio(index);

    gaEvent({action: 'menu_click', label: types[index].name});
  }

  render() {
    let { currentContent, fontSize } = this.state;

    return (
      <div className="App">
        <Layout>
          <Content>
            <TopMenu 
              updateModule={this.updateModule} 
              currentContent={currentContent}
              saveFontSize={this.saveFontSize.bind(this)}
              fontSize={fontSize}
            />
          </Content>
        </Layout>
      </div>
    );
  }
}

export default App;