import React from 'react';
import { Row, Col } from 'antd';

const styles = {
  oneline: {
    display: 'inline-block'
  },
  divider: {
    display: 'inline-block',
    width: 40
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginBottom: 10
  },
  subTitle: {
    fontSize: '0.8em',
    textAlign: 'center'
  },
  c1: {
    textAlign: 'left'
  },
  c2: {
    textAlign: 'right',
    paddingRight: 3
  },
  c3: {
    textAlign: 'right',
    paddingRight: 3,
    paddingLeft: 3
  },
  parent: {
    paddingLeft: 10,
    paddingRight: 10
  },
  spacing: {
    display: 'inline-block',
    width: 10
  },
  note: {
    color: '#888888'
  }
};

function Spacing() {
  return (
    <span style={styles.spacing}> </span>
  )
}

function ZaoWanXianXiangLi() {
  return (
    <div style={styles.parent}>
      <Row>
        <Col span={24} style={styles.title}>早晚獻香禮節</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing />作揖<Spacing />跪</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing />獻香五炷<Spacing />獻香三炷<Spacing />獻香三炷<Spacing />獻香三炷<Spacing />獻香一炷</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}>明明上帝</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>十叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />諸天神聖</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />彌勒祖師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />南海古佛</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />活佛師尊</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />月慧菩薩</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />各位法律主</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />灶君</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />師尊</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />師母</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />鎮殿元帥</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />鎮殿將軍</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />教化菩薩</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />各位大仙</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />老前人</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />前人</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />點傳師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />引保師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />前人大眾</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />自己祖先</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col><Spacing /></Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={{...styles.c1, ...styles.note}}><Spacing />默唸愿懺文:</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />餘蘊(信士) ΟΟΟ 虔心跪在</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />明明上帝蓮下<Spacing />幸受真傳</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />彌勒祖師<Spacing />妙法無邊<Spacing />護庇眾生</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />懺悔佛前<Spacing />改過自新<Spacing />同註天盤</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />凡係佛堂<Spacing />顛倒錯亂</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />望祈<Spacing />祖師<Spacing />赦罪容寬</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>十叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />南無阿彌十佛天元</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>十叩首</Col>
      </Row>
      <Row>
        <Col><Spacing /></Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />金公祖師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />天然古佛</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />中華聖母</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />白水聖帝</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />不休息菩薩</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />白陽大仙</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>

      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />謝謝</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />老母大慈大悲</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一百叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />起<Spacing />作揖</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />獻香禮畢<Spacing />放手<Spacing />鞠躬<Spacing />退</Col>
      </Row>
    </div>
  )
}

export default ZaoWanXianXiangLi;