import React from 'react';
import { Row, Col } from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';

function Header(props) {
  const styles = {
    title: {
      textAlign: 'center',
      padding: 30,
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: 2,
      zIndex: 0,
      marginTop: 20
    },
    icon: {
      padding: 5,
      fontSize: 20
    },
    iconParent: {
      position: 'absolute',
      right: 20
    }
  };

  return (
    <Row justify="space-around" align="middle">
      <Col span={4} />
      <Col span={12} style={styles.title}><h1>經訓背誦</h1></Col>
      <Col span={4} />
      <div style={styles.iconParent}>
        <PlusCircleOutlined
          onClick={props.setFontSize.bind(this, true)}
          style={styles.icon}
        />
        <MinusCircleOutlined
          onClick={props.setFontSize.bind(this, false)}
          style={styles.icon}
        />
      </div>
    </Row>
  )
}

export default Header;