import React, { useState } from 'react';
import {
  UpCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';

import MyHeader from './MyHeader';
import CanCiJiaLi from './CanCiJiaLi';
import PlayVideo from './PlayVideo';
import ZaoWanXianXiangLi from './ZaoWanXianXiangLi';

import { formatContent, scrollToTop, gaEvent } from '../util/util';

const styles = {
  upButton: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 20,
    paddingBotton: 50
  },
  video: {
    marginTop: 80,
    // width: '100vw',
    maxWidth: 480,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    textAlign: 'center',
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginBottom: 10
  },
  audio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const backToTop = _ => {
  scrollToTop(500);
  gaEvent({action: 'button_click', label: 'back_to_top'});
};

function MenuContent(props) {
  const [ repeat, setRepeat ] = useState(false);

  let year = '';
  if (props.type.type === 'JingShenZhiBiao') {
    year = (new Date()).getFullYear();
  }

  const handleRepeat = id => {
    let newRepeat = !(!!repeat);
    setRepeat(newRepeat);
    let obj = document.getElementById(id);
    obj.loop = newRepeat;
  };

  if (props.type.media && props.type.media === 'video') {
    return (
      <div style={{fontSize: props.fontSize}}>
        <MyHeader setFontSize={props.saveFontSize}/>
        <div>
          <PlayVideo type={props.type} />
          {
            formatContent(props.currentContent)
          }
        </div>
        <div style={styles.upButton}>
          <UpCircleOutlined onClick={backToTop}/>
        </div>
        <div>&nbsp;</div>
      </div>
    )
  } else {
    return (
      <div style={{fontSize: props.fontSize}}>
        <MyHeader setFontSize={props.saveFontSize}/>
        <div>
          <div style={styles.audio}>
            <div onClick={handleRepeat.bind(this, props.type.name)}>
              {
                repeat ? <SyncOutlined spin /> : <SyncOutlined />
              }
            </div>
            <audio controls id={props.type.name}>
              <source src={`//studyaudio.fycd.us/audio/${props.type.type}${year}.mp3`} type="audio/mpeg"/>
              <source src={`//study.fycd.us/audio/${props.type.type}${year}.ogg`} type="audio/ogg"/>
            </audio>
          </div>
          {
            (props.type.type !== 'CanCiJiaLi' && props.type.type !== 'ZaoWanXianXiangLi') && formatContent(props.currentContent)
          }
          {
            props.type.type === 'CanCiJiaLi' && <CanCiJiaLi />
          }
          {
            props.type.type === 'ZaoWanXianXiangLi' && <ZaoWanXianXiangLi />
          }
        </div>
        <div style={styles.upButton}>
          <UpCircleOutlined onClick={backToTop}/>
        </div>
        <div>&nbsp;</div>
      </div>
    )
  }
}

export default MenuContent;