import React from 'react';
import { Row, Col } from 'antd';

const styles = {
  oneline: {
    display: 'inline-block'
  },
  divider: {
    display: 'inline-block',
    width: 40
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginBottom: 10
  },
  subTitle: {
    fontSize: '0.8em',
    textAlign: 'center'
  },
  c1: {
    textAlign: 'left'
  },
  c2: {
    textAlign: 'right',
    paddingRight: 3
  },
  c3: {
    textAlign: 'right',
    paddingRight: 3,
    paddingLeft: 3
  },
  parent: {
    paddingLeft: 10,
    paddingRight: 10
  },
  spacing: {
    display: 'inline-block',
    width: 10
  },
  note: {
    color: '#888888'
  }
};

function Spacing() {
  return (
    <span style={styles.spacing}> </span>
  )
}

function CanCiJiaLi() {
  return (
    <div style={styles.parent}>
      <Row>
        <Col span={24} style={styles.title}>參（辭）駕禮節</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing />作揖<Spacing />跪</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}>明明上帝</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>五叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />諸天神聖</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing />彌勒祖師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>三叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />南海古佛</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />活佛師尊</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />月慧菩薩</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />師尊</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />師母</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />點傳師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />引保師</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 16, offset: 1}} md={{span: 7, offset: 7}} style={styles.c1}><Spacing /><Spacing />前人大眾</Col>
        <Col xs={{span: 6}} md={{span: 3}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={{...styles.c1, ...styles.note}}><Spacing /><Spacing />(若是點傳師在場時)</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />起<Spacing />作揖<Spacing />跪<Spacing />向</Col>
      </Row>
      <Row>
        <Col xs={{span: 17, offset: 1}} md={{span: 8, offset: 7}} style={styles.c1}><Spacing /><Spacing />點傳師參（辭、接、送）駕</Col>
        <Col xs={{span: 5}} md={{span: 2}} style={styles.c2}>一叩首</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={{...styles.c1, ...styles.note}}><Spacing /><Spacing />(若是點傳師不在場時則以上免叩)</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />起<Spacing />作揖</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />參（辭、接、送）駕禮畢</Col>
      </Row>
      <Row>
        <Col xs={{span: 23, offset: 1}} md={{span: 17, offset: 7}} style={styles.c1}><Spacing /><Spacing />放手<Spacing />鞠躬<Spacing />退</Col>
      </Row>
    </div>
  )
}

export default CanCiJiaLi;